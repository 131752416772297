@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

$accent-color: #d4c486;

@font-face {
  font-family: 'Cantata One';
  font-style: normal;
  font-weight: 400;
  src: local('Cantata One'), local('CantataOne-Regular'), url('/blog/css/fonts/CantataOne-Regular.ttf') format('truetype');
}

body {
  color: #ddd;
  background-color: #230b2d;
  font-family: serif;
  margin: 0;
}
.main-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.main-content {
    width: 120ex;
    max-width: 100%;
}
.main-sidebar {
    min-width: 40ex;
}
@media screen and (min-width: 1000px) {
	body {
		background-image: url(./pics/bg.png);
	}
    .main-flex {
        flex-flow: row nowrap;
    }
    .main-sidebar {
        min-width: 40ex;
        max-width: 80ex;
        margin-left: 2em;
    }
}
.copyright {
  color: #ffffff;
}
.footer {
  text-align: center;
}
.footer a {
  color: #ee4444;
}
.sidebar h1 {
  color: #ddd;
}
.sidebar li {
  margin-top: .7em;
  line-height: 1em;
}

a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

.well {
  background-color: #25292a;
  box-shadow: 0px 0px 0px 0px, 0px 8px 16px rgba(0, 0, 0, 0.2);
  border: 0px;
  border-radius: 6px;
  margin: 1em;
  padding: 1em;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.3em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

.author {
  color: #ee4444;
}
.author a {
  color: #ee4444;
}

img {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

img + em {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

blockquote {
  color: #bbb;
  font-size: 1em;
}

.center-image
{
  margin: 0 auto;
  display: block;
}

//********************************************************
//                    Links
//********************************************************
a {
  color: $accent-color;
}

//********************************************************
//                    Post
//********************************************************
.post-title {
  margin-top: 0;
  text-align: left;
}
hr.post-divider {
	border-color: #777;
	border-style: dashed;
    border-width: 1px 0 0 0;
    margin: 2ex;
}
article {
	line-height: 1.6em;

	hr {
		border-color: #555;
	}

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 1em;
		font-family: 'Cantata One', serif;
	}
	h1 {
	ont-size: 1.7em;
	}

	> .post-title {
		margin-bottom: 1em;
		font-family: 'Cantata One', serif;
	}

	p + p {
		margin-top: 1em;
	}

    header a {
        font-size: 2rem;
        text-decoration: none;
    }
    header a:hover {
        text-decoration: underline;
    }
	header .post-time {
		font-size: 1rem;
		display: inline;
		float: right;
		font-family: serif;
	}
	footer {
		margin-top: 2em;
		ul.article-tags {
			display: inline;
		}
		ul > li {
			display: inline;
		};
	}

	// rust playground link code highlight
	figure.highlight {
		position: relative;
	}

	figure.highlight a.playground {
		position: absolute;
		z-index: 10;
		float: right;
		right: 8px;
		top: 8px;
		outline: none;
	}

	@media not screen {
		figure.highlight a.playground {
			display: none;
		}
	}

	// foldable blocks
	figure.foldable {
		> input {
			display: none;
		}

		> label {
			position: absolute;
			z-index: 100;
			left: 50%;
			margin-right: 50%;
			transform: translate(-50%, 0);

			font-weight: normal;
			cursor: pointer;
			color: $accent-color;
			font-size: 90%;
			text-align: center;
		}

		@media not screen {
			> input:checked ~ div {
				max-height: none;
			}
		}

		@media screen {
			> input:not(:checked) ~ label::after {
				content: "expand";
			}

			> input:checked ~ label::after {
				content: "collapse";
			}

			/* Default State */
			> figure {
				max-height: 15em;
				overflow-y: scroll;
			}

			/* Toggled State */
			> input:checked ~ figure {
				max-height: none;
				overflow-y: hidden;
			}
		}
	}
}

//    _  _     _
//   | \| |___| |_ ___
//   | .` / _ \  _/ -_)
//   |_|\_\___/\__\___|
//

.note {
  position:relative;
  border: 0;
  padding-left: 30px;
}
.note:before {
  content: "\f06a";
  font-size: 2em;
  font-family: FontAwesome;
  left: 0px;
  position:absolute;
  top: 0;
  color: #87CEFA;
}
.warning:before {
  content: "\f071";
  color: yellow;
}

//    _  _             _
//   | || |___ __ _ __| |___ _ _
//   | __ / -_) _` / _` / -_) '_|
//   |_||_\___\__,_\__,_\___|_|
//
nav a {
  font-family: serif;
}
nav.navbar {
  min-height: 15px;
  background-color: #1a0d20;
  display: flex;
  padding: 0.4ex;

  .navbar-header {
    height: inherit;
    font-size: 2rem;
    flex-grow: 1;
    padding-left: 0.3ex;
  }

  ul {
    padding-right: 2ex;

    li {
      display: inline-block;
      font-size: 1.25em;
      padding-left: 2ex;
    }
  }
}

//********************************************************
//                    Footer
//********************************************************
body > footer {
  text-align: center;
}

// other stuff by me
.pagination {
	width: 100%;
	padding-top: 1em;
	padding-bottom: 1em;
	display: flex;
	> a, > span {
		flex: 1;
		font-size: 1.2em;
	}
	.newer {
		text-align: left;
	}
	.archive {
		text-align: center;
	}
	.older {
		text-align: right;
	}
}
.header-desc {
	font-size: 0.5em;
	display: block;
}
